import * as THREE from 'three'
import { AudioLoader } from 'three';
import Experience from "./Experience"
import $ from 'jquery'

export default class InteractionSounds {
  constructor() {
    this.experience = new Experience()
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.scene = experience.scene

    this.sound_listener = new THREE.AudioListener();
    this.camera.add(this.sound_listener);
    this.parcel_selection_audioLoader = new AudioLoader();
    this.parcel_option_audioLoader = new AudioLoader();

    this.parcelSound = new THREE.Audio(this.sound_listener);
    this.optionBuffer

    this.parcelSelectionSetup();
    this.optionSelectionSetup();

  };

  parcelSelectionSetup() {
    this.parcel_selection_audioLoader.load('sounds/parcel_select.wav', (buffer) => {
      this.parcelSound.setBuffer(buffer);
      this.parcelSound.setLoop(false); // Set to true for continuous play
      this.parcelSound.setVolume(0.3); // Adjust volume
    });
  };

  optionSelectionSetup() {
    this.parcel_option_audioLoader.load('sounds/mellau_whoosh-short-3.wav', (buffer) => {
      this.optionBuffer = buffer
    });
  };

  playParcelSelection() {
    if ($('#sound-toggle .sound-on').hasClass('active') && $('#sound-toggle .fa-volume').length) {
      this.parcelSound.play(); // Play the sound
    }
  }

  playOptionSelection() {
    if ($('#sound-toggle .sound-on').hasClass('active') && $('#sound-toggle .fa-volume').length) {
      const sound = new THREE.Audio(this.sound_listener);
      sound.setBuffer(this.optionBuffer);
      sound.setLoop(false);  // Set to true if you want continuous overlapping play
      sound.setVolume(0.3);  // Adjust volume as needed
      sound.play();
    }
  }
}