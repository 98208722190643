import * as THREE from 'three'
import { AudioLoader } from 'three';
import Experience from "../Experience"
import $ from 'jquery'

export default class RainSound {
  constructor() {
    this.experience = new Experience()
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.scene = experience.scene

    this.rainListener = new THREE.AudioListener();
    this.camera.add(this.rainListener);
    this.rainAudioLoader = new AudioLoader();

    this.rainBuffer
    this.sound

    this.rainFiles = [
      'sounds/rain1.wav',
      'sounds/rain2.mp3',
      'sounds/rain3.mp3',
      'sounds/rain4.wav',
      'sounds/rain5.wav',
      'sounds/rain6.mp3',
    ]

    this.rainSoundAudio = []

    this.setupRainSounds()

  };

  setupRainSounds() {
    for (let index = 0; index < this.rainFiles.length; index++) {
      this.rainAudioLoader.load(this.rainFiles[index], (buffer) => {
        this.rainSoundAudio[index] = new THREE.Audio(this.rainListener);
        this.rainSoundAudio[index].setBuffer(buffer);
        this.rainSoundAudio[index].setLoop(false);
        this.rainSoundAudio[index].setVolume(1);
      });
    }
  }

  playRainSound(index) {
    if ($('#sound-toggle .sound-on').hasClass('active') && $('#sound-toggle .fa-volume').length) {
      this.rainSoundAudio[index - 1].play()
    }
  }

  stopRainSound() {
    this.rainSoundAudio.forEach(sound => {
      if (sound.isPlaying) {
        sound.pause();
      }
    });
  }
}