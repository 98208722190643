import * as THREE from 'three'
import { AudioLoader } from 'three';
import Experience from '../Experience';

export default class BackgroundMusic {
  constructor() {
    this.experience = new Experience()

    this.musicListener = new THREE.AudioListener();
    this.experience.camera.instance.add(this.musicListener);
    this.audioLoader = new AudioLoader();
    this.backgroundSound = new THREE.Audio(this.musicListener)

    this.setupMusic();
  };

  setupMusic() {
    this.audioLoader.load('sounds/Watershed_Rock.mp3', (buffer) => {
      this.backgroundSound.setBuffer(buffer);
      this.backgroundSound.setLoop(true); // Set to true for continuous play
      this.backgroundSound.setVolume(0.3); // Adjust volume
    });
  };

  playMusic() {
    this.backgroundSound.play(); // Play the sound
  }

  stopMusic() {
    this.backgroundSound.pause(); // stop the sound
  }

  setVolume(level) {
    this.backgroundSound.setVolume(level);
  }
}